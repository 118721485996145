import {listenChange} from 'use-change';
import { gsap } from "gsap";
import { MotionPathPlugin } from "gsap/MotionPathPlugin.js";
import Store, {getItem} from '../../../Store'
import {getZoomedViewboxRect, Rect2VB} from './RectUtils'
import {track} from '../../../lib/Stats'

gsap.registerPlugin(MotionPathPlugin);

const SELECTOR_ACTIONS_LAYER = '#NODES'
const SELECTOR_HIDEONZOOM = '#HIDEONZOOM'
const SELECTOR_BG = '#BACKGROUND'
const SELECTOR_ANIMATION_PATHS = '#CONNECTIONS'
const SELECTOR_HITAREA = '[id*="HITAREA"]'
const SELECTOR_MARKER = '[id*="MARKER"]'

const elements = {
  groups: [],
  bg: null,
  hideonzoom: null,
  layeredPaths: {}
}
Object.keys(Store.SVGConnections).forEach( l => {
  elements.layeredPaths[l] = []
})


const selected = {el:null}

const verifyLayers = (doc) => {
  if( !doc.querySelector(SELECTOR_ACTIONS_LAYER) ){
    console.error('SVG Error - Missing layer:', SELECTOR_ACTIONS_LAYER)
    return false
  }
  if( !doc.querySelector(SELECTOR_BG) ){
    console.error('SVG Error - Missing layer:', SELECTOR_BG)
    return false
  }
  if( !doc.querySelector(SELECTOR_HIDEONZOOM) ){
    console.error('SVG Error - Missing layer:', SELECTOR_HIDEONZOOM)
    return false
  }

  return true
}

const setup = (svgRootSelector) => {
  console.log('FlowController: setup flow:', Store.flow)

  setTimeout( () => track('flow'), 100 )

  const doc = document.querySelector(svgRootSelector)

  if( ! verifyLayers(doc )) return;

  // prepare elements
  elements.hideonzoom = doc.querySelector(SELECTOR_HIDEONZOOM)
  gsap.to(elements.hideonzoom, {attr:{opacity:1}, ease:"power4.Out", duration:0})

  
  // prepare selection
  selected.el = null

  // prepare bg
  doc.querySelector(SELECTOR_BG).style.display = 'none'
  elements.bg = doc.parentElement
  gsap.to(elements.bg, {backgroundColor:'#D9E8EC', ease:"power4.Out", duration:0})
  // elements.bg = doc.querySelector(SELECTOR_BG)
  // elements.bg.removeAttribute('style')
  // gsap.to(elements.bg, {attr:{fill:'#DAE7EB'}, ease:"power4.Out", duration:0})

  // prepare doc
  document.querySelector('div[data-panel="flowchart"]').style.top = 0;

  doc.addEventListener('click', () => {
    if( selected.el ){
      Store.detail = null
      Store.item = null
      setTimeout( () => track('flow'), 100 )
    }
  })

  listenChange(Store, 'detail', (detail) => {
    console.log("listenChange(Store, 'detail', (detail) => {", detail)
    if( detail ){
      setTimeout( () => track('detail'), 100 )
    }else{
      setTimeout( () => track('item'), 100 )
    }
  })
  
  listenChange(Store, 'presentMedia', (presentMedia) => {
    if( presentMedia ){
      setTimeout( () => track('media'), 100 )
    }
  })

  listenChange(Store, 'item', (item) => {

    console.log("listenChange(Store, 'item', (item) => {", item)
    if( item ){
      setTimeout( () => track('item'), 100 )
    }else{
      setTimeout( () => track('flow'), 100 )
    }

    const ease = "power4.Out"
    const zoomdelay = 0.1

    if( item ){
      // zoom in - show item
      console.log('listenChange item (to show)', item);
      
      selected.el = item
      
      // gsap.to(elements.bg, {backgroundColor:'#d5d5d5', ease:"power4.Out", duration:1}) 
      gsap.to(elements.hideonzoom, {attr:{opacity:0.2}, ease:"power4.Out", duration:1})

      elements.groups.forEach( obj => {
        if( obj.grp.id === item ){
          // zoom in

          // calc target viewbox
          const r = getZoomedViewboxRect(obj.hitarea)
          const vb = Rect2VB(r)
          gsap.to(doc, {attr:{viewBox:vb}, ease, duration:1, delay:zoomdelay})         

          // zoom to precalculated viewbox
          // gsap.to(doc, {attr:{viewBox:obj.vb}, ease, duration:1, delay:zoomdelay})         
          
          gsap.to(obj.grp, {attr:{opacity:1}, ease:"power4.Out", duration:1})
        }else{
          // hide others
          gsap.to(obj.grp, {attr:{opacity:0.2}, ease:"power4.Out", duration:1})
        }
        // hide markers
        gsap.to(obj.marker, {attr:{opacity:0}, ease:"power4.Out", duration:1})
      })

    }else{
      console.log('zoom out');
      // if( selected.el ){
        // zoom out - show all
        selected.el = null        

        gsap.to(doc, {attr:{viewBox:'0 0 1920 1080'}, ease, duration:1, delay:zoomdelay})
        
        // gsap.to(elements.bg, {backgroundColor:'#D9E8EC', ease:"power4.Out", duration:1})
        gsap.to(elements.hideonzoom, {attr:{opacity:1}, ease:"power4.Out", duration:1})
        
        elements.groups.forEach( obj => {
          gsap.to(obj.grp, {attr:{opacity:1}, ease:"power4.Out", duration:1})
          gsap.to(obj.marker, {attr:{opacity:1}, ease:"power4.Out", duration:1})
        })

      // }
    }
  })


  listenChange(Store, 'SVGConnections', (layers) => {
    // console.log('Flowcontroller - Store.SVGConnections changed:', layers);
    Object.keys(layers).forEach( k => {

      // hide/show the svg layer
      const UCKey = k.toUpperCase()
      const layer = doc.querySelector(`g#CONNECTIONS > #${UCKey}`)
      if( layer ){
        layer.style.display = layers[k] ? 'block' : 'none';
      }else{
        console.log(UCKey, layers[k], layer)
      }

      // hide / show the path animations
      elements.layeredPaths[k].forEach( o => {
        if( o.clone ){
          o.clone.style.display = layers[k] ? 'block' : 'none';
        }
      })


    });
  });

  setupActions(doc);
  
  setTimeout( () => {
    setupAnimations(doc);
    console.log('elements:', elements );

  }, 500);

}


const setupAnimations = (doc) => {
  console.log('setupAnimations')
  
  const pathMarker = doc.querySelector('#PATHMARKER')
  pathMarker.style.display = 'block'

  const knownLayers = Object.keys(Store.SVGConnections)
  knownLayers.forEach( l => {
    elements.layeredPaths[l].forEach( o => {
      o.tween?.kill()
      o.clone?.remove()
    })
  })
  knownLayers.forEach( l => {
    elements.layeredPaths[l] = []
  })

  doc.querySelectorAll(SELECTOR_ANIMATION_PATHS +' path').forEach( (p,i) => {
    
    const s = p.getAttribute('style')
    // console.log('p s', p, s)
    const m = s.match(/rgb.*\)/gm)
    if( !m ){
      console.log('path:', i, 'no match', m, s, p )
    }else{
    
      const pathLength = MotionPathPlugin.getLength(p)
      const duration = pathLength / 50
      const parent = p.closest('g [id]')
      const layer = parent.getAttribute('id').toLowerCase()
      if( !knownLayers.includes(layer) ){
        console.log('Unknown layer', layer)
      }
      
      // console.log('path:', i, 'length', pathLength, 'layer:', layer )

    
      const clone = pathMarker.cloneNode(true)

      if( m ){
        clone.style.stroke = m
      }

      elements.hideonzoom.appendChild( clone )

      const tween = gsap.to(clone, {keyframes:
        [
          {scale: 0, duration:0},
          { delay: 0,
            transformOrigin: "50% 50%",
            duration: duration,
            ease: "none",
            motionPath: {
                path: p,
                align: p,
                alignOrigin: [0.5, 0.5],
                autoRotate: true
            },
          },
          {scale: 0.6, duration:0.25, delay:-duration},
          {scale: 0, duration:0.25, delay: duration-0.5},
        ],
        repeat:-1,
      })

      elements.layeredPaths[layer].push({clone, tween})
      
    }
  })
  pathMarker.style.display = 'none'
}

const setupActions = (doc) => {

  doc.querySelectorAll(SELECTOR_ACTIONS_LAYER +' > g').forEach(grp => {
    
    // disable mouse for all nested groups (machine icons)
    grp.querySelectorAll('g').forEach( childgroup => {
      childgroup.classList.add('nopointer')
    })

    const hitarea = grp.querySelector(SELECTOR_HITAREA)
    if( !hitarea ) console.warn('WARN: Could not find HITAREA in ', grp.id)
    hitarea.classList.remove('nopointer')
    hitarea.classList.add('hitarea')
    
    const marker = grp.querySelector(SELECTOR_MARKER)
    if( !marker ) console.warn('WARN: Could not find MARKER in ', grp.id)
    marker.classList.add('pulse')

    elements.groups.push( {grp, marker, hitarea} )
    
    hitarea.addEventListener('click', (e) => {
      console.log('Clicked', grp.id);
      e.preventDefault()
      e.stopPropagation()
      
      console.log('@Clicked', Store.item, grp.id)

      if( Store.item === grp.id ){
        Store.item = null
        setTimeout( () => {
          console.log('clearing Store.itemStore')
          Store.itemStore = null
        }, 1000)
      }else{
        
        const d = getItem(grp.id)
        if( d ){
          Store.itemData = d.dbitem
        }else{
          Store.itemData = null
        }

        Store.item = grp.id;
      }
    })
  })  
  
}

export {
  elements,
  setup
}