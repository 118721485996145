import React, { useState, useLayoutEffect } from "react";
import { useValue, useSet } from "use-change";
import useMobileDetect from "use-mobile-detect-hook";

import { ChooseFlow } from "./components/ChooseFlow/ChooseFlow";
import { Flowchart } from "./components/Flowchart/Flowchart";
import { Item } from "./components/ItemView/Item";
import { Detail } from "./components/DetailView/Detail";

import { MediaPresentor } from "./components/MediaPresentor/MediaPresentor";
import { SecretButton } from "./components/SecretButton"

import "./App.css";
import loadergif from "./loader.gif"

export const App = () => {

  const item = useValue("item");
  const detail = useValue("detail");
  const ready = useValue("_ready");
  const setMobile = useSet("_isMobile");

  const [loaded, setLoaded] = useState(false);

  const detectMobile = useMobileDetect()


  useLayoutEffect(() => {
    
    if( !loaded ){
      setLoaded(true);
    }

    // prefer web version on iPads
    const isIPad = navigator.userAgent.match(/iPad/i)
    
    const isMobile = !isIPad && detectMobile.isMobile()

    setMobile( isMobile )

    if (isMobile) {
      document.body.classList.add('mobile')
    }

  }, [detectMobile, loaded, ready, setMobile]);


  if (!ready || !loaded ) {
    return <div className="loadingContainer"><div style={{
      backgroundImage: `url(${loadergif})`
    }}></div></div>;
  }

  let layoutClassIndex = 0;
  if (item) layoutClassIndex += 1;
  if (detail) layoutClassIndex += 1;

  const layoutClass = ["one", "two", "three"][layoutClassIndex];

  return (
    <div id="container" className={"container"} data-layout={layoutClass}>
      <ChooseFlow />
      <Flowchart />
      <Item />
      <Detail />
      <SecretButton />
      <MediaPresentor />
    </div>
  );
};

export default App;
