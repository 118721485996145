import React, {useLayoutEffect, useState, createRef} from "react";
import { useSet, useValue } from "use-change";
import styles from "./MediaHeader.module.css";

import {AiFillPlayCircle as VideoIcon} from "react-icons/ai";


const checkIfWeHaveVideo = (vf, vu) => {
  if( !vf ) return false
  if( !vu ) return false
  if( vf === 'none' ) return false
  if( vu === 'none' ) return false

  return true
}

const MediaHeader = (props) => {

  const setPresentMedia = useSet("presentMedia");
  const detail = useValue("detail")

  const ref = createRef(null)
  
  const hasVideo = checkIfWeHaveVideo(props.video_file, props.video_url)
  // console.log('MediaHeader: hasVideo', hasVideo, props)
  // console.log('MediaHeader: props.image', props.image)

  const pushx  = props.x || 0
  const height = props.h || 100
  const bgc    = props.bg || '#ccc'
  
  const style = {
    backgroundImage: `url(${props.image})`,
    marginLeft: `${pushx}px`,
    height: `${height}px`,
    backgroundColor: bgc,
    opacity: props.mode === "detail" ? 0 : 1
  };

  const [prevDetail, setPrevDetail] = useState(null)
  
  const present = () => {
    // console.log('MediaHeader@present hasVideo:', hasVideo)
    setPresentMedia( {hasVideo, video_file: props.video_file, video_url: props.video_url, image: props.image} );
  };

  let Content = null

  if( hasVideo ) {
    // console.log('MediaHeader: Video')
    Content = (
      <div>
        <div className={styles.playbutton} onClick={present}>
          <VideoIcon size={120} fill={'#1bb5a9'} />
        </div>
      </div>)
  
}else if(props.image && props.image !== 'none' ){
    // console.log('MediaHeader: Image hitbox')
    Content = (<div className={styles.hitbox} onClick={present} />)
  }

  useLayoutEffect( () => {
    if( props.mode === "detail" && props.image && props.image !== 'none' ){
      console.log('1 MediaHeader useLayoutEffect', detail, prevDetail)
      if( detail !== prevDetail ){
        console.log('2 MediaHeader useLayoutEffect GO', detail, prevDetail)
        if( ref.current ){
          ref.current.style.opacity = 0
        }
        const img = new Image()
        img.onload = () => {
          console.log('4 MediaHeader image loaded', ref.current, document.querySelector('#mediaheader_detail'))

          if( ref.current ){
            ref.current.style.opacity = 1
          }else if( document.querySelector('#mediaheader_detail') ){
            document.querySelector('#mediaheader_detail').style.opacity = 1
          }
        }
        img.src = props.image
        console.log('3 MediaHeader loading image')
        setPrevDetail(detail)
      }
      
    }else{
      if( ref.current ){
        ref.current.style.opacity = 1
      }
    }
  }, [detail, prevDetail, props.image, props.mode, ref])

  return (
    <div id={`mediaheader_${props.mode}`} className={styles.container} data-elm={"mediaheader"} style={style} ref={ref}>
      {Content}
    </div>
  );
};

export default MediaHeader;
