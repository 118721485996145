import React from "react";
import styles from "./Close.module.css";
import Store from '../../Store';

import { IoMdHome as HomeIcon } from "react-icons/io";
import { CgClose as CloseIcon } from "react-icons/cg";

const Close = ({ cb = () => null, home = false }) => {

  const style = Store._isMobile
    ? {width:'60px', height:'60px'}
    : {width:'90px', height:'90px'};

  return (
    <div className={styles.closeWrap}>
      <div className={styles.closeButton} onClick={() => cb()} data-elm={"closebtn"} style={style}>
        <div className={styles.closeButtonSurface}>
          {home && <HomeIcon size={45} fill={'#002A54'} />}
          {!home && <CloseIcon size={50} color={'#002A54'} />}
        </div>
      </div>
    </div>
  );
};

export default Close;
