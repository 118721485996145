import Store from "../Store";
import {cleanNodes} from './cleaner'

const DIRECTUS_REMOTE = "https://cms.missionzeromine.com"
const DIRECTUS_LOCAL  = "http://localhost:8055"

const ACCESS_TOKEN = "apitoken"; // FIXME: Grab from .env somehow

const directus = {
  electron: false,
  addr: DIRECTUS_REMOTE,
  // addr: DIRECTUS_LOCAL,
};




// for all:
// http://localhost:8055/items/flowsheets?fields=*.*.*.*.*&access_token=apitoken
//
// for assets:
// http://localhost:8055/assets/79b0239e-e03b-44a8-bdeb-970351ddb40b?access_token=apitoken
//
// for settings:
// http://localhost:8055/items/settings?fields=*.id&access_token=apitoken

const LoadAssetByKey = (key) => {
  if (!Store._ready || !Store.settings ) return "";
  if (!Object.keys(Store.settings.data).includes(key)) return "";

  const itm = Store.settings.data[key].id;
  return `${directus.addr}/assets/${itm}?access_token=${ACCESS_TOKEN}`;
};

const LoadImageById = (id) => {
  if (!id ) return;
  if ( id === -1 ) id = '5e22813c-ec9d-4578-84bb-68a450bcb48e' // image not found
  return `${directus.addr}/assets/${id}?access_token=${ACCESS_TOKEN}`;
};

const LoadData = async (type, query) => {
  const dataurl = `${directus.addr}/${query}&access_token=${ACCESS_TOKEN}`;

  const options = {
    method: "get",
    headers: {
      "Content-Type": "application/json",
    },
  };
  
  let json = {};

  try {
    let response = await fetch(dataurl, options);
    if (response.status >= 400 && response.status < 600) {
      console.warn("Bad response from server", response);
    } else {
      json = await response.json();
      json = { updated_at: Date.now(), data: json.data };    
      if( type === 'nodes' ){
        json = cleanNodes(json)  
      }
    }
  } catch (error) {
    console.warn("Bad response from server", error);
    json = null
  }

  console.log('json data', type, json)

  return json;
};

export default LoadData;

export { LoadAssetByKey, LoadImageById };
