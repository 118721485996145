import React, { useRef } from "react";
import { useSet, useValue } from "use-change";
import ReactHtmlParser from "react-html-parser";
import { LoadImageById } from "../../lib/Dataloader";
import Close from "../Close/Close";
import { Link } from "../Link/";
import MediaHeader from "../MediaHeader/MediaHeader";
import "../../App.css";

import styles from "./Detail.module.css";

export const Detail = () => {
  
  const setDetail = useSet("detail");
  const data = useValue("detailData");
  const isMobile = useValue("_isMobile")
  const panelRef = useRef(null);

  if (!data) {
    
    const style = isMobile 
      ? { top: "100vh" }
      : { left: "100vw" };

    return (
      <div
        data-panel="detail"
        data-type="panel"
        ref={panelRef}
        style={style}
      >
        NO DATA
      </div>
    );
  }

  console.log('Detail using data:', data)

  return (
    <div data-panel="detail" data-type="panel" ref={panelRef} style={{top:'0'}}>
        <Close cb={() => setDetail(null)} />
      <div className={styles.header}>
        <h2>{data.title && ReactHtmlParser(data.title)}</h2>
      </div>
      
      <div className={styles.panelContent}>
        <MediaHeader
          image={LoadImageById(data.image.id || null)}
          video_file={data.video_file || 'none'}
          video_url={data.video_url || 'none'}
          x={0}
          h={496}
          bg={"#002A54"}
          mode="detail"
        />

        <div className={styles.page_container}>
          <div className={styles.page}>
            <div className={styles.content}>
              <h3>{data.abstract && ReactHtmlParser(data.abstract)}</h3>

              <div className={styles.bullets}>
                {data.bullets && ReactHtmlParser(data.bullets)}
              </div>

              <Link source="detail" />
              
            </div>
          </div>
        </div>
      </div>

    </div>
  );
};
