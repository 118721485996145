
import LoadData from './Dataloader';

const Load = async (type) => {

    let query = ''

    switch( type ){
        case 'data':
            query = `items/flowsheets?fields=*.*.*.*&sort=order`;
            break;

        case 'settings':
            query = `items/settings?fields=*.id,*.type`;
            break;
        
        case 'nodes':
            //query = `items/nodes?fields=*.*.*.*.*.*`; // too much... 12.5MB -> 590Kb :)
            query = `items/nodes?fields=*.*,*.image.id,*.link_list.links_id.*,*.icon_list.icons_id.*,*.icon_list.icons_id.file.id,*.asset_list.assets_id.*,*.asset_list.assets_id.link_list.links_id.*,*.asset_list.assets_id.*.id`;
            break;

        default:
            console.log('Error: Load called with invalid $type:', type);
            return null;
    }

    return LoadData(type, query)

}


export default Load
