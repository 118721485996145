
import sanitizeHtml from 'sanitize-html';

const sanitizerOptions = {
  allowedTags: [ 'p', 'span', 'b', 'i', 'em', 'strong', 'a', 'ul', 'li', "strong", "sub", "sup" ],
  allowedAttributes: {},
  allowedIframeHostnames: []
};

export const cleanNodes = (json) => {
  // console.time('Sanitized node-data')
  // console.log('cleanNodes', json)

  const cleaned = {}
  cleaned.data = json.data.map( (itm, index) => {
    if( itm.dbitem?.title ) itm.dbitem.title = sanitizeHtml(itm.dbitem.title, sanitizerOptions)
    if( itm.dbitem?.abstract ) itm.dbitem.abstract = sanitizeHtml(itm.dbitem.abstract, sanitizerOptions)
    if( itm.dbitem?.bullets ) itm.dbitem.bullets = sanitizeHtml(itm.dbitem.bullets, sanitizerOptions)

    if( itm.dbitem?.asset_list ){
      itm.dbitem.asset_list = itm.dbitem.asset_list.map( a => {
        if( a.assets_id?.abstract ) a.assets_id.abstract = sanitizeHtml(a.assets_id.abstract, sanitizerOptions)
        if( a.assets_id?.bullets )  a.assets_id.bullets = sanitizeHtml(a.assets_id.bullets, sanitizerOptions)
        
        if( a.items_id?.abstract ) a.items_id.abstract = sanitizeHtml(a.items_id.abstract, sanitizerOptions)
        if( a.items_id?.bullets ) a.items_id.bullets = sanitizeHtml(a.items_id.bullets, sanitizerOptions)
        
        // yes, our structure is one level too deep :|
        if( a.items_id?.asset_list ){
          a.items_id.asset_list = a.items_id.asset_list.map( b => {
            if( b.assets_id?.abstract ) b.assets_id.abstract = sanitizeHtml(b.assets_id.abstract, sanitizerOptions)
            if( b.assets_id?.bullets )  b.assets_id.bullets = sanitizeHtml(b.assets_id.bullets, sanitizerOptions)

            if( b.items_id?.abstract ) b.items_id.abstract = sanitizeHtml(b.items_id.abstract, sanitizerOptions)
            if( b.items_id?.bullets ) b.items_id.bullets = sanitizeHtml(b.items_id.bullets, sanitizerOptions)
            
            return b
          })
        }
        return a
      })
    }

    return itm
  })

  // console.timeEnd('Sanitized node-data')
  return cleaned
}
