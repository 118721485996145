import React from "react";
import { useValue } from "use-change";
import styles from "./Link.module.css";

export const Link = ({source}) => {

  const ddata = useValue("detailData");
  const idata = useValue("itemData");
  const _hideExternalLinks = useValue("_hideExternalLinks");
  // const _isElectron = useValue("_isElectron");

  // if( _isElectron ){
  //   return null
  // }

  if( _hideExternalLinks ){
    return null
  }

  let link_list = []
  let style = {}

  if( source === 'detail' ){
    link_list = ddata.link_list
    style.backgroundColor = '#FFF'
  }
  if( source === 'item'){
    style.backgroundColor = '#F0F0F0'
    link_list = idata.link_list
  }

  // console.log("link: source:", source, "link_list:", link_list, link_list.length)

  if( !link_list?.length ) {
    return null
  }

  return (
    <div className={styles.container} style={style}>
      <div className={styles.col}>
        {link_list.map( (link, index) => (
          <p key={index}>
          Learn more about<br />
          <strong><a href={link.links_id.url.substr(0,4) !== 'http'? `http://${link.links_id.url}`:link.links_id.url} target="_blank" rel="noreferrer">{link.links_id.text}</a></strong>
          </p>
        ))}
      </div>
      {/* <div>
        <div className={styles.btn}>
          <a href="https://www.flsmidth.com/" target="_blank" rel="noreferrer">FLSMIDTH.COM</a>
        </div>
      </div> */}
    </div>
  );
};
