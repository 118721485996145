import React, { useState, useRef } from "react";
import useChange, { useValue } from "use-change";
import styles from "./LayerMenu.module.css";

import { FaChevronDown, FaChevronUp } from "react-icons/fa";

const LayerMenu = () => {
  const flow = useValue("flow");
  const store = useValue("data");
  const [item /*, setItem */] = useChange("item") // for the debug
  const [svgConnections, setSvgConnections] = useChange('SVGConnections');

  // const isMobile = useValue("_isMobile");
  // const isSticky = useValue('_sticky');

  const [open, setOpen] = useState(true);
  const [height, setHeight] = useState(0)
  const ref = useRef(null)
  
  const toggleItem = (evnt, index) => {
    const key = evnt.target.getAttribute('data-type');
    console.log('toggleItem', key);

    if( svgConnections.hasOwnProperty(key) ){
      svgConnections[key] = !svgConnections[key]
      setSvgConnections( {...svgConnections })
    }
  }

  const toggleOpen = () => {

    // if we're in mobile and Sticky mode - a click should lead us back to the map
    // if( isMobile && isSticky ){
    //   console.log('LayerMenu toggleOpen isMobile/isSticky');
    //   setItem(null)
    //   return
    // }

    setOpen(!open)
    // console.log('toggleOpen', open );
    // const h = ref.current.clientHeight
    // console.log('h', h);
    if( open ){
      setHeight(ref.current.clientHeight)
    }else{
      setHeight(0)
    }
  }

  if( !flow ){
    return null
  }

  return (
    <div className={styles.container} data-elm="layermenu">

      <div data-elm="debugitemmonitor" className={styles.debugItemMonitor}>{item ? item : 'NONE'}</div>

      <div className={styles.panelMask} style={{height:height+'px'}} data-open={open?'true':'false'}>

        <div className={styles.panel} ref={ref}>
          
          <div className={styles.legendContainer} >
            
            <div className={styles.legendItem} data-type="main">
              <div className={styles.chip} data-selected="true"></div>
              <div className={styles.label}>Main flow</div>
              <div className={styles.marker}></div>
            </div>

            <div className={styles.legendItem} data-type="secondary" onClick={(e) => toggleItem(e)}>
              <div className={styles.chip} data-selected={svgConnections['secondary']?'true':'false'}></div>
              <div className={styles.label}>Secondary flow</div>
              <div className={styles.marker}></div>
            </div>

            <div className={styles.legendItem} data-type="recycled" onClick={(e) => toggleItem(e)}>
              <div className={styles.chip} data-selected={svgConnections['recycled']?'true':'false'}></div>
              <div className={styles.label}>Recycled</div>
              <div className={styles.marker}></div>
            </div>
          </div>
        </div>

      </div>


      <div className={styles.bigitem} onClick={toggleOpen}>
        <div id="layermenuTitle">{store.data.filter((f) => f.id === flow)[0].title}</div>
        <div>
          {!open && (<FaChevronUp size={28} />)}
          {open && (<FaChevronDown size={28} />)}
        </div>
      </div>
    </div>
  );
};

export default LayerMenu;
