import Store from "../../../Store";

const getZoomedViewboxRect = (el, pad=20) => {
  const box = getBBox(el)
  const windowWidth = window.innerWidth || 1920;
  // const factor = 1920 / (1920 - 1080); // ≈ 2.28 @ 1920
  const factor = Store._isMobile
    ? 1
    : windowWidth / (windowWidth - 1080);

 
  return {
    x: box.x - pad,
    y: box.y - pad,
    w: (box.width + pad*2) * factor,
    h: box.height + pad*2
  }
}

const VB2Rect = (el) => {
  const vb = el.getAttribute('viewBox').split(' ').map(e => parseInt(e) )
  return {
    x: vb[0],
    y: vb[1],
    w: vb[2],
    h: vb[3]
  }
}

const Rect2VB = (rect) => {
  return `${Math.max(0,rect.x)} ${Math.max(0,rect.y)} ${Math.max(0,rect.w)} ${Math.max(0,rect.h)}`
  // return `${rect.x} ${rect.y} ${rect.w} ${rect.h}`
}

// https://greensock.com/forums/topic/13681-svg-gotchas/?page=2&tab=comments#comment-72060
/**
 * @param {SVGElement} element - Element to get the bounding box for
 * @param {boolean} [withoutTransforms=false] - If true, transforms will not be calculated
 * @param {SVGElement} [toElement] - Element to calculate bounding box relative to
 * @returns {SVGRect} Coordinates and dimensions of the real bounding box
 */
 const getBBox = (element, withoutTransforms, toElement) => {
  
  var svg = element.ownerSVGElement;
  
  if (!svg) {
    return { x: 0, y: 0, cx: 0, cy: 0, width: 0, height: 0 };
  }
  
  var r = element.getBBox(); 
  
  if (withoutTransforms) {
    return {
      x: r.x,
      y: r.y,
      width: r.width,
      height: r.height,        
      cx: r.x + r.width / 2,
      cy: r.y + r.height / 2
    };
  }
  
  var p = svg.createSVGPoint(); 
      
  var matrix = (toElement || svg).getScreenCTM().inverse().multiply(element.getScreenCTM()); 

  p.x = r.x;
  p.y = r.y;
  var a = p.matrixTransform(matrix);

  p.x = r.x + r.width;
  p.y = r.y;
  var b = p.matrixTransform(matrix);

  p.x = r.x + r.width;
  p.y = r.y + r.height;
  var c = p.matrixTransform(matrix);

  p.x = r.x;
  p.y = r.y + r.height;
  var d = p.matrixTransform(matrix);

  var minX = Math.min(a.x, b.x, c.x, d.x);
  var maxX = Math.max(a.x, b.x, c.x, d.x);
  var minY = Math.min(a.y, b.y, c.y, d.y);
  var maxY = Math.max(a.y, b.y, c.y, d.y);
    
  var width = maxX - minX;
  var height = maxY - minY;
  
  return {
    x: minX,
    y: minY,
    width: width,
    height: height,        
    cx: minX + width / 2,
    cy: minY + height / 2
  };
}





export {
  VB2Rect,
  Rect2VB,
  getBBox,
  getZoomedViewboxRect,
}