import React from "react";
import { useValue } from "use-change";
import { LoadImageById } from "../../lib/Dataloader";
import styles from "./Icon.module.css";

export const Icon = () => {
  const data = useValue("itemData");

  if( !data ){
    return null
  }

  return (
    <div className={styles.container} >
      {data?.icon_list.map( (icon, index) => ( 
        <img key={index} src={LoadImageById( icon.icons_id.file.id ? icon.icons_id.file.id : -1)} alt={icon.icons_id.name} />
      ))}
    </div>
  )
}
