import React from 'react';
import ReactDOM from 'react-dom';
import { Provider as UseChangeProvider } from "use-change";
import Store from './Store'
import App from './App';
import './vars.css';
import './index.css';
import './mobile.css';
import './font-proxima-nova.css';
import './font-noto-sans.css';
import './electron.css';

if (process.env.NODE_ENV !== 'development') {
  console.log = () => {}
}

ReactDOM.render(
  <React.StrictMode>
    <UseChangeProvider value={Store}>
      <div className="App">
        <App />
      </div>
    </UseChangeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
