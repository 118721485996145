import Store from '../Store'

let flow = ''
let item = ''
let detail = ''
let media = ''

let autoNavigationEnabled = false
let timeSinceLastNavigation = Date.now()
let movingToHome = false

export const track = (type) => {

  // dont log while we auto-navigate to homescreen
  if( movingToHome ) return

  if( type === 'home' ){
    flow = ''
    item = ''
    detail = ''
    media = ''
  }

  if( type === 'flow' ){
    flow = Store.flow === 1 ? 'MZM' : 'TDY'
    item = ''
    detail = ''
    media = ''
  }
  
  if( type === 'item' || type === 'media'){
    item = Store.itemData?.title || 'unknown-item'
    detail = ''
    media = ''
  }
  
  if( type === 'detail' || type === 'media'){
    detail = Store.detailData?.title || 'unknown-detail'
    media = ''
  }

  if( type === 'media' ){
    media = Store.presentMedia.hasVideo ? 'video' : 'image'
  }

  const page = [flow, item, detail, media].filter(p => p !== '')
  const record = page.join('/').toLowerCase().replace(/\s/g, '_').replace(/[\u{0080}-\u{FFFF}]/gu, '');
  
  console.log('track', type, page, record )
  
  if( Store._isElectron && window.ipcRenderer ){
    window.ipcRenderer.invoke('track', `${new Date().toISOString()};${record}`)
  }else{
    window.location.href = `#${record}`
  }
  
  timeSinceLastNavigation = Date.now()


}

const checkAuto = () => {
  const now = Date.now()
  const lapse = now - timeSinceLastNavigation
  console.log( 'checkAuto', lapse, flow )
  if( lapse > (1000 * 60 * 15) ){
    if( flow ){
      console.log( 'checkAuto: navigate to home' )
      movingToHome = true
      Store.detail = null
      Store.item = null
      Store.media = null
      Store.flow = null
      setTimeout( () => {
        movingToHome = false
        timeSinceLastNavigation = now
      }, 500 );
    }
  }
}

export const enableAutoNavigation = () => {
  console.log('enableAutoNavigation', autoNavigationEnabled)
  if( autoNavigationEnabled ) return
  autoNavigationEnabled = true

  setInterval( () => {
    checkAuto()
  }, 1000 )
}

