import React from 'react'
import useChange from 'use-change'
import Store from '../../Store'
import Close from '../Close/Close'
import styles from './MediaPresentor.module.css'

export const MediaPresentor = () => {

    const [presentMedia, setPresentMedia] = useChange('presentMedia')

    if( !presentMedia ){
        return null;
    }

    console.log('MediaPresentor.presentMedia', presentMedia )

    const hasVideo = presentMedia.hasVideo || false

    let PlayerElement = (<div className={styles.container} data-mode=""><div className={styles.content}>404</div></div>)
    
    if( !hasVideo && presentMedia.image && presentMedia.image !== 'none'){
        console.log('MediaPresentor using ImagePlayer')
        PlayerElement = (<div className={styles.imageContainer} style={{backgroundImage: `url(${presentMedia.image})`}}></div>)
    }
    if( hasVideo ){
        console.log('MediaPresentor using VideoPlayer')

        // Note: We might be in a renderer-process with a different env than the main app - so get our stuff from $window
        // console.log('MediaPresentor process.env', process.env)
        // console.log('MediaPresentor window.process.env', window.process.env)

        if( Store._isElectron ){
            // test:
            // presentMedia.video_file = 'were-at-minexpo-2021-september-8-video.mp4'
            // presentMedia.video_file = 'https://video.flsmidth.com/attachment/19476794/20435023/553589a78c9b5a41f359773ab574704c/video_hd/the-ok-mill-the-most-reliable-and-0-video.mp4'

            if( presentMedia.video_file ){
                let mode = ''
                let root_directory = ""
                if( window.process.env.ELECTRON_START_URL ){
                    console.log('MediaPresentor is DEV', window.__dirname, window.process.env)
                    mode = 'DEV'
                    // window.__dirname:
                    // /Users/js/repos/fls-missionzero/node_modules/electron/dist/Electron.app/Contents/Resources/electron.asar/renderer
                    
                    // root_directory  = '../../../../../../../../' // 8 -> fls-missionzero
                    // root_directory += 'backend'
                    root_directory = window.__dirname.split('node_modules')[0] +'backend'
                }else{
                    console.log('MediaPresentor is APP', window.__dirname, window.process.env)
                    mode = 'APP'
                    // runtime __dirname:
                    // "/Users/js/repos/fls-missionzero/dist/mac-arm64/missionzeromine.app/Contents/Resources/app.asar/build"
                    //  /Users/js/repos/fls-missionzero/dist/mac-arm64/fls-video-localstore/were-at-minexpo-2021-september-8-video.mp4
                    // "/Users/js/Desktop/missionzeromine.app/Contents/Resources/app.asar/build"
                    root_directory = window.__dirname.split('/missionzeromine.app')[0]
                }

                // the cms holds the full path to the movie, e.g.
                // 'https://video.flsmidth.com/attachment/19476794/20435023/553589a78c9b5a41f359773ab574704c/video_hd/the-ok-mill-the-most-reliable-and-0-video.mp4',
                // we only needs the last part:
                const video_file = presentMedia.video_file.split('/').pop()

                console.log('MediaPresentor VARS', {
                    'mode': mode,
                    'root_directory': root_directory,
                    'Store.VIDEO_DIRECTORY': Store.VIDEO_DIRECTORY,
                    'video_file': video_file,
                    'window.__dirname': window.__dirname
                })
                
                const videopath = `${root_directory}/${Store.VIDEO_DIRECTORY}/${video_file}`
                // hmm.. seems we need relative-path??
                console.log('MediaPresentor:isElectron Using video with path:', videopath)
                PlayerElement = (
                    <video className={styles.videoplayer} controls autoPlay>
                        <source src={videopath} type="video/mp4"></source>
                    </video>)
                // PlayerElement = (<video className={styles.videoplayer} src={videopath} controls />)
                // PlayerElement = (<video src={videopath} controls />)
            }else{
                console.warn('MediaPresentor:isElectron Error: Missing video_file in data:', presentMedia)
            }
        }else{
            console.log('MediaPresentor: Using FLS iframe player')
            // https://www.twentythree.com/help/embedding-your-videos
            // Set default quality of video - defaultQuality=<standard|hd|fullhd>
            // https://video.flsmidth.com/v.ihtml/player.html?photo_id=71133110&defaultQuality=fullhd
            PlayerElement = (<iframe src={presentMedia.video_url+'&defaultQuality=auto'} title="fls-player" width="100%" height="100%" style={{border:'none'}}></iframe>)
        }
    }

    if( PlayerElement === false ){
        setPresentMedia(null)
        return null
    }

    return (
        <div className={styles.container} data-mode="show">
            <div className={styles.content}>
                {PlayerElement}        
                <Close cb={() => setPresentMedia(null) } />
            </div>
        </div>
    )
}