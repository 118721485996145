import React from 'react'
import {useValue, useSet} from "use-change";
import ReactHtmlParser from "react-html-parser";
import {LoadAssetByKey} from '../../lib/Dataloader';
import '../../App.css'
import styles from './ChooseFlow.module.css'
import { ReactComponent as RightArrow } from '../../icons/right_arrow.svg'

export const ChooseFlow = () => {
  const setFlow = useSet('flow');
  const store = useValue('data');

  return (
    <div className="container" data-panel="flow" data-type="panel" style={{backgroundImage: `url( ${LoadAssetByKey('level0_background_image')})`}}>

      <div className={styles.overlay_gradient}></div>

      <div className={styles.tllogo}>
        <img src={LoadAssetByKey('fls_logo')} alt="FLSmidth Logo" />
      </div>
      <div className={styles.brlogo}>
        <img src={LoadAssetByKey('fls_missionzero_logo')} alt="The MissionZero Mine Logo" />
      </div>

      <div className={styles.wrap}>
        {store.data.map( (node) => {
          return (
            <div className={styles.button} onClick={() => setFlow(node.id)} style={{backgroundColor: node.color}} key={node.id}>
              <div className={styles.title}>{ReactHtmlParser(node.title)}</div>
              <div className={styles.text}>{ReactHtmlParser(node['text'])}</div>
              <div className={styles.arrow}><RightArrow /></div>              
            </div>)
        })}
      </div>

    </div>
  
  )
}