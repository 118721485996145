import React, { useLayoutEffect, useRef } from "react";
import useChange, { useValue, useSet } from "use-change";
import ReactHtmlParser from "react-html-parser";

import { LoadImageById, LoadAssetByKey } from "../../lib/Dataloader";
import MediaHeader from "../MediaHeader/MediaHeader";
import Close from "../Close/Close";
import {Icon} from "../Icon";
import { Link } from "../Link/";

import "../../App.css";
import styles from "./Item.module.css";
import liststyles from "./ItemsMenu.module.css";

export const Item = () => {
  
  const setItem = useSet("item");
  const [detail, setDetail] = useChange("detail");
  const data = useValue("itemData");
  const setDetailData = useSet("detailData");
  const isMobile = useValue("_isMobile")
  
  const panelRef = useRef(null);
  const titleRef = useRef(null);

  useLayoutEffect( () => {
    if( isMobile ){
      if( titleRef.current ){
        if( panelRef.current ){
          const h = window.innerHeight - titleRef.current.getBoundingClientRect().height
          // console.log('Item, titleRef ->  panelRef.current.style.top ', `${h}px` )
          panelRef.current.style.top = `${h}px`
        }
      }
    }
  })
  

  if (!data){

    const style = isMobile 
      ? { top: "100vh" }
      : { left: "100vw" };

    return (
      <div
        data-panel="item"
        data-type="panel"
        ref={panelRef}
        style={style}
      >
        NO DATA
      </div>
    );
  }
  
  const selectAsset = (obj) => {
    console.log('selectAsset:', obj)
    setDetail(obj.id);
    setDetailData({ ...obj, image: obj.image || { id: null } });
  };

  const CustomIcon = ({ icon }) => {
    const src = icon
      ? LoadImageById(icon.id)
      : LoadAssetByKey("default_asset_icon");

    return <img alt="customicon" src={src} className={styles.customIcon} />;
  };

  const renderMenu = () => {

    if( data.asset_list?.length === 0 ){
      return (<div />)
    }

    return (
      <div>
      <div className={liststyles.container} data-elm={"itemsmenu"}>
        <h3>{data.bulletlist_title}</h3>
        {data.asset_list.map((item, i) => {

          if( !item.assets_id ) return (<div className="error">{'DATA: Item > Asset error - is the Asset List correct in the CMS?'}</div>)
          if( !item.assets_id.menuicon ) return (<div className="error">{'DATA: Item > Asset: menuicon missing'}</div>)
          if( !item.assets_id.menutitle ) return (<div className="error">{'DATA: Item > Asset: menutitle missing'}</div>)
          
          return (
            <div
              key={i}
              className={liststyles.item}
              onClick={() => selectAsset(item.assets_id)}
              data-selected={item.assets_id.id === detail ? "true" : "false"}
            >
              <div className={liststyles.icon}>
                <CustomIcon icon={item.assets_id.menuicon} />
              </div>
             <div className={liststyles.label}>{item.assets_id.menutitle}</div>
            </div>
          );
        })}
      </div>

      <Icon />

      </div>
    )
  };

  console.log('Item using data:', data)

  return (
    <div data-panel="item" data-type="panel" ref={panelRef}>

      
      <div className={styles.header} data-elm={"header"} ref={titleRef}>
        <h2>{ReactHtmlParser(data.title)}</h2>
        <Close
          cb={() => {
            if( panelRef.current ){
              console.log("close reset scrolltop on panelRef (item)");
              panelRef.current.scrollTop = 0;
            }
            const container = document.querySelector('#container')
            if( container ){
              container.scrollTo(0,0);
            }

            setItem(null);
          }}
        />
        
      </div>

      <div className={styles.panelContent}>
        <MediaHeader
          image={LoadImageById( (data && data.image) ? data.image.id : -1)}
          video_file={data.video_file}
          video_url={data.video_url}
          x={186}
          h={496}
          bg={"#72325f"}
          mode="item"
        />

        <div className={styles.page_container}>
          <div className={styles.page}>
            
            { !isMobile && renderMenu() }

            <div className={styles.content}>
              <div>
                <h3>{ReactHtmlParser(data.abstract)}</h3>

                <div>{ReactHtmlParser(data.bullets)}</div>

                {data.link_list && <Link source="item" />}

                { isMobile && renderMenu() }

              </div>
            </div>

          </div>
        </div>
      </div>

      {/* <Close
        cb={() => {
          if( panelRef.current ){
            console.log("close reset scrolltop on panelRef (item)");
            panelRef.current.scrollTop = 0;
          }
          const container = document.querySelector('#container')
          if( container ){
            container.scrollTo(0,0);
          }

          setItem(null);
        }}
      /> */}
    </div>
  );
};
