import React, { useState, useEffect } from "react";
import useChange, { useSet } from "use-change";
import LayerMenu from "../LayerMenu/LayerMenu";
import { setup } from "./utils/FlowController";
import Close from "../Close/Close";
import {track} from '../../lib/Stats'

// remember to export these Style Attributes (not Style Elements) from Illustrator
import { ReactComponent as SVG_MZ }  from "./svgs/MZa.svg";
import { ReactComponent as SVG_TDY } from "./svgs/TDYas.svg";

import styles from "./Flowchart.module.css";
import './FlowchartSVGStyles.css'

export const Flowchart = () => {
  const [flow, setFlow] = useChange('flow');
  const setItem = useSet("item");
  const setDetail = useSet("detail");
  const setSvgConnections = useSet("SVGConnections");

  const [previousFlow, setPreviousFlow] = useState(null);
  
  useEffect(() => {
    // console.log('Flowchart: useEffect', flow, previousFlow);
    if( previousFlow !== flow ){
      setPreviousFlow(flow)
      if( flow ){
        setup("#svgwrapper > svg");
      }
    }
    
    document.querySelector('div[data-panel="flowchart"]').style.top = flow ? 0 : '100vh'

  }, [flow, previousFlow] );
   
  const reset = () => {    
    setDetail(null);
    setItem(null);
    setFlow(null);
    setSvgConnections({
      'main': true,
      'secondary': true,
      'recycled': true
    })
    setTimeout( () => track('home'), 100 )
  };

  return (
    <div data-panel="flowchart" className={styles.container}>
      <div id="svgwrapper-outer">
        <div id="svgwrapper" className={styles.wrapper}>
          {flow===1 && <SVG_MZ />}
          {flow===2 && <SVG_TDY />}
        </div>
      </div>
      <div id="menuwrapper">
        <LayerMenu />
        {flow && <Close cb={() => reset()} home={true} />}
      </div>
    </div>
  );
};
