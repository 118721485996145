import Load from "./lib/Load";
import {enableAutoNavigation} from "./lib/Stats"

const Store = {
  // electron, directory containing the local videos
  // expected to be next to the .app
  // if changed, also update install/download-videos.sh
  VIDEO_DIRECTORY: 'fls-video-localstore',

  // app state
  _ready: false,
  _isElectron: false,
  _isMobile: false,
  _hideExternalLinks: false,
  
  // ui state
  // TODO: Get from URL/hash
  flow: null, // 1:force mission-zero, 2:force mine-of-today (set to null otherwise)
  item: null,
  detail: null,

  // transport:
  itemData: null,
  detailData: null,

  // the layer menu
  SVGConnections: {
    'main': true,
    'secondary': true,
    'recycled': true
  },

  // present
  presentMedia: null,
  
  // data
  data: { data: [] },

  // setting (the cms table)
  settings: {},

  // nodes (the cms table holding links from SVG-IDS to Item-slugs)
  nodes: {},
};

const init = async () => {
  Store._ready = false;

  try {
    Store.settings = await Load("settings");
    Store.nodes = await Load("nodes");
    Store.data = await Load("data");
     
  }finally{
    
    if( !Store.settings || !Store.nodes || !Store.data ){
      console.log('Could not load data. Aborting')

      if( Store._isElectron ){
        setTimeout( () => {
          console.log('ready/load timeout. reloading')
          init()
        }, 5000 );
      }

      return
    }else{
      Store._ready = true;

      if( Store._isElectron ){
        Store._hideExternalLinks = true;
        enableAutoNavigation()
      }

    }
  }

  console.log("@Store: Load complete", Store);
};


const getItem = (item) => {
  console.log('@Store getItem item:', item);

  const ID = item ? item : Store.item

  // strict match
  // const res = Store.nodes.data.filter( node => node.svgid === ID )[0]

  // loose match to work around the random strings sometimes appended to the SVG IDs (layer names) by Illustrator
  // So, if the Illustrator layer is called "MZ_IPCC", and the database ID is "MZ_IPCC", and the SVG layer ends up as "MZ_IPCC_00002307602364829346928346928346"
  // it will still match.
  // const res = Store.nodes.data.filter( node => node.svgid.startsWith(ID) )[0]
  const res = Store.nodes.data.filter( node => ID.startsWith(node.svgid) )[0]
  
  if( !res ){
    console.warn('@Store Error: No link between SVG ID', ID, 'and DB-Item defined. See the Nodes collection in the CMS.')
    return undefined
  }

  // console.log('@Store getItem result:', res)
  
  return res;
  
}

if( window.navigator.userAgent.indexOf('Electron') > 0 ){
  Store._isElectron = true;
  console.log('Electron:', true);
  document.body.setAttribute("data-electron", true)
}

init();

export default Store;

export { getItem };

// window.getItem = getItem;
window.Store = Store;

window.init = init