import React from "react";
import styles from "./SecretButton.module.css";
import Store from '../../Store'

const REQUIRED_CLICKS = 4

export const SecretButton = () => {
  const [clickCount, setClickCount] = React.useState(0);
  const [toRef, setToRef] = React.useState(null);
  
  const OnClick = () => {
    setClickCount( clickCount +1 );    

    if( clickCount >= REQUIRED_CLICKS ){
      setClickCount(0);
      console.log('SecretButton Action!')

      if( window.ipcRenderer ){
        window.ipcRenderer.invoke('minimize', true)
      }
    
    }else{
  
      clearTimeout( toRef )
      setToRef(setTimeout( () => {
        console.log('SecretButton TO clickCount', clickCount)
        setClickCount(0)
        console.log('SecretButton clearing')
      }, 2000 ))

    }
  }

  if( !Store._isElectron ){
    // return <div className={styles.container} />
    return null
  }
  
  return (
    <div className={styles.container} onClick={OnClick}>{REQUIRED_CLICKS-clickCount}</div>
  )
}
